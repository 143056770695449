import { CASUS_IDS, DOCUMENT_FLOW_MODES, isModeDocumentFlow, SubRecord } from '___types'
import initialWizardState, {
  WizardState,
  fullAssign,
  initializeSections,
  updateQuestionOrder,
  applyParagraphNumbering,
  evaluateMarkers,
  initializeAnswerRelevance,
} from '.'
import { FOUND_SEGMENT_REFERENCES } from './editor-content'

import { foundSegmentReferences } from '../../helpers/editor-content'

const resetWizard = (): WizardState => {
  Object.keys(FOUND_SEGMENT_REFERENCES).forEach(key => delete FOUND_SEGMENT_REFERENCES[key as keyof typeof FOUND_SEGMENT_REFERENCES])
  Object.keys(foundSegmentReferences).forEach(key => delete foundSegmentReferences[key as keyof typeof foundSegmentReferences]) // TEMPORARY
  return Object.assign({}, JSON.parse(JSON.stringify(initialWizardState)))
}

export type InitializeWizardPayload = SubRecord<WizardState>
const initializeWizard = (payload: UpdateWizardStatePayload = {}): WizardState =>
  updateWizardState(Object.assign({}, resetWizard()), Object.assign({}, { initialized: true }, payload))

export type UpdateWizardStatePayload = SubRecord<WizardState>
const UPDATED_WIZARD_CONDITIONAL_METHODS = {
  applyDataStructureId: {
    conditional: (_: WizardState, payload: UpdateWizardStatePayload = {}): boolean => 'dataStructure' in payload,
    method: (state: WizardState): WizardState =>
      fullAssign({}, state, { dataStructure: Object.assign({}, state.dataStructure, { id: CASUS_IDS.DATASTRUCTURE_ID }) }) as WizardState,
  },
  initializeSections: {
    conditional: (_: WizardState, payload: UpdateWizardStatePayload = {}): boolean => 'dataStructure' in payload,
    method: (state: WizardState): WizardState => initializeSections(state),
  },
  applyParagraphNumbering: {
    conditional: (_: WizardState, payload: UpdateWizardStatePayload = {}): boolean => 'dataStructure' in payload,
    method: (state: WizardState): WizardState => applyParagraphNumbering(state),
  },
  initializeAnswerRelevance: {
    conditional: (currentState: WizardState, payload: UpdateWizardStatePayload = {}, previousState: WizardState): boolean =>
      isModeDocumentFlow(payload.mode || currentState.mode) &&
      ('questions' in payload || ('answers' in payload && Boolean((payload.answers || previousState.answers)?.length))),
    method: (state: WizardState): WizardState => initializeAnswerRelevance(state),
  },
  updateQuestionOrder: {
    conditional: (currentState: WizardState, payload: UpdateWizardStatePayload = {}, previousState: WizardState): boolean =>
      isModeDocumentFlow(payload.mode || currentState.mode) &&
      ('questions' in payload ||
        'questionLayout' in payload ||
        ('answers' in payload && Boolean((payload.answers || previousState.answers)?.length))),
    method: (state: WizardState): WizardState => updateQuestionOrder(state),
  },
  evaluateMarkers: {
    conditional: (currentState: WizardState, payload: UpdateWizardStatePayload = {}, previousState: WizardState): boolean =>
      isModeDocumentFlow(payload.mode || currentState.mode) &&
      ('locations' in payload || 'integrations' in payload || ('answers' in payload && Boolean((payload.answers || previousState.answers)?.length))),
    method: (state: WizardState): WizardState => applyParagraphNumbering(evaluateMarkers(state, false)),
  },
} as const

const updateWizardState = (state: WizardState, payload: UpdateWizardStatePayload): WizardState =>
  fullAssign(
    {},
    state,
    Object.values(UPDATED_WIZARD_CONDITIONAL_METHODS).reduce(
      (currentState, { conditional, method }) => (conditional(currentState, payload, state) ? method(currentState) : currentState),
      Object.assign({}, state, payload) as WizardState
    )
  ) as WizardState

export type SetWizardTemplateIdPayload = string
const setWizardTemplateId = (state: WizardState, payload: SetWizardTemplateIdPayload): WizardState => {
  if (state.templateId === payload || !(typeof payload === 'string' || payload === null)) return state
  return updateWizardState(state, { templateId: payload })
}
const resetWizardTemplateId = (state: WizardState): WizardState =>
  state.templateId ? initializeWizard({ mode: DOCUMENT_FLOW_MODES.TEMPLATE_SELECT }) : state
export type UpdateWizardTitlePayload = string
const updateWizardTitle = (state: WizardState, payload: UpdateWizardTitlePayload): WizardState => updateWizardState(state, { title: payload })

export { initializeWizard, resetWizard, updateWizardState, setWizardTemplateId, resetWizardTemplateId, updateWizardTitle }
