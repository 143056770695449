import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { UseMutateFunction, UseQueryResult } from 'react-query'

import { DOCUMENT_DIRECTORY, ITemplate } from '___types'
import {
  CreateTemplateFunctionType,
  RemoveTemplateFunctionType,
  ShareTemplateFunctionType,
  ToggleCompanyShareTemplateFunctionType,
  UnshareTemplateFunctionType,
  UpdateTemplateFunctionType,
  useCreateTemplate,
  useFetchTemplate,
  useRemoveTemplate,
  useShareTemplate,
  useUnshareTemplate,
  useUpdateTemplate,
  UploadImageVariables,
  useUploadTemplatePreviewImage,
  useFetchTemplatePreviewImageSrcURL,
  useToggleCompanyShareTemplate,
} from '___queries'
import useDuplicateTemplate, { DuplicateTemplateFunctionType } from '___queries/templates/useDuplicateTemplate'
import useTemplateOwned from './useTemplate.Owned'
import useTemplatePaywallBlocked from './useTemplate.PaywallBlocked'

export type UseTemplateHookUnknownIdReturnType = { [K in keyof UseQueryResult<ITemplate>]?: UseQueryResult<ITemplate>[K] } & {
  create?: CreateTemplateFunctionType
  duplicate?: DuplicateTemplateFunctionType
  remove?: RemoveTemplateFunctionType
  share?: ShareTemplateFunctionType
  toggleCompanyShare?: ToggleCompanyShareTemplateFunctionType
  unshare?: UnshareTemplateFunctionType
  update?: UpdateTemplateFunctionType
  creating: boolean
  duplicating?: boolean
  removing?: boolean
  sharing?: boolean
  unsharing?: boolean
  updating?: boolean
  //
  use?: () => void
  owned?: boolean
  previewImageSrcURL?: UseQueryResult<string | undefined>
  paywallBlocked?: boolean
  uploadPreviewImage?: UseMutateFunction<string | undefined, unknown, UploadImageVariables, unknown>
}

// ======================= USE TEMPLATE OVERLOAD ======================= //
function useTemplate(id?: null, publicFlow?: boolean): { create: CreateTemplateFunctionType; creating: boolean }
function useTemplate(
  id: string,
  publicFlow?: boolean
): UseQueryResult<ITemplate> & {
  duplicate: DuplicateTemplateFunctionType
  remove: RemoveTemplateFunctionType
  share: ShareTemplateFunctionType
  toggleCompanyShare: ToggleCompanyShareTemplateFunctionType
  unshare: UnshareTemplateFunctionType
  update: UpdateTemplateFunctionType
  creating: boolean
  duplicating: boolean
  removing: boolean
  sharing: boolean
  togglingCompanyShare: boolean
  unsharing: boolean
  updating: boolean
  //
  use: () => void
  owned: boolean
  paywallBlocked: boolean
  previewImageSrcURL: UseQueryResult<string | undefined>
  uploadPreviewImage: UseMutateFunction<string | undefined, unknown, UploadImageVariables, unknown>
}
// ===================================================================== //
function useTemplate(id?: string | null, publicFlow: boolean = false) {
  const template = useFetchTemplate(id, publicFlow)
  const { create, creating } = useCreateTemplate()
  const { duplicate, duplicating } = useDuplicateTemplate(id)
  const { remove, removing } = useRemoveTemplate(id)
  const { share, sharing } = useShareTemplate(id)
  const { toggleCompanyShare, toggling: togglingCompanyShare } = useToggleCompanyShareTemplate(id)
  const { unshare, unsharing } = useUnshareTemplate(id)
  const { update, updating } = useUpdateTemplate(id)
  const owned = useTemplateOwned(id, publicFlow)
  const paywallBlocked = useTemplatePaywallBlocked(id, publicFlow)

  const history = useHistory()
  const use = useCallback(() => history.push(`/${DOCUMENT_DIRECTORY}/${id}/new?step=configure`), [history, id])
  const uploadPreviewImage = useUploadTemplatePreviewImage(id)
  const previewImageSrcURL = useFetchTemplatePreviewImageSrcURL(id)

  if (!id) return { create, creating }

  return Object.assign({}, template, {
    duplicate,
    remove,
    share,
    toggleCompanyShare,
    unshare,
    update,
    creating,
    duplicating,
    removing,
    sharing,
    togglingCompanyShare,
    unsharing,
    updating,
    //
    use,
    owned,
    paywallBlocked,
    previewImageSrcURL,
    uploadPreviewImage,
  })
}

export default useTemplate
