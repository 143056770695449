import React, { FunctionComponent, useState, useMemo, useCallback, useEffect } from 'react'
import useStore, { SelectedTemplateIdSelector, DeselectTemplatesAction } from '___store'

import { useTemplate, useUser } from '___hooks'
import { Cross, Spinner, TemplatePlaceholder } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import {
  Actions,
  Info,
  Relocation,
  // Publishing,
  Sharing,
} from './Template.components'
import { MainLayoutRightPaneTemplateProps, mainLayoutRightPaneTemplateClasses as classes } from '../../..'
import CompanySharing from './Template.components/CompanySharing'

type UseStoreHookResultType = {
  selectedTemplateId: SelectedTemplateIdSelector
  deselectTemplates: DeselectTemplatesAction
}

export const Template: FunctionComponent<MainLayoutRightPaneTemplateProps> = React.memo(() => {
  const [renaming, setRenaming] = useState(false)
  const { selectedTemplateId, deselectTemplates } = useStore('selectSelectedTemplateId', 'deselectTemplates') as UseStoreHookResultType

  const {
    data: template,
    // isLoading,
    // isFetching,
    duplicate,
    update,
    remove,
    share,
    toggleCompanyShare,
    unshare,
    owned,
    togglingCompanyShare,
    previewImageSrcURL: { data: src, isLoading: imageLoading } = {},
  } = useTemplate(selectedTemplateId!)

  const { data: user } = useUser()

  const { sharedCompanyTemplates } = user || {}

  const { sharedWith } = template || {}

  const templatePreview = useMemo(() => {
    if (imageLoading) return <Spinner />
    return src ? <img src={src} alt="Template Preview" /> : <TemplatePlaceholder randomize />
  }, [imageLoading, src])

  const renamingCallback = useCallback(() => {
    if (renaming) {
      const inputWrapper = document.getElementById(`MainLayout-RightPane-Template-${selectedTemplateId}-Input`)
      ;(inputWrapper?.firstChild as HTMLPreElement)?.focus()
    } else setRenaming(true)
  }, [renaming, selectedTemplateId])

  const renameCallback = useCallback(
    name => {
      if (name) update({ name })
      setRenaming(false)
    },
    [update]
  )

  const duplicateCallback = useCallback(() => {
    duplicate()
  }, [duplicate])

  const removeCallback = useCallback(() => {
    remove()
    deselectTemplates()
  }, [remove, deselectTemplates])

  const shareCallback = useCallback((email, use = true, edit = false) => share(email, use, edit), [share])

  const unshareCallback = useCallback(id => unshare(id), [unshare])

  const toggleCompanyShareCallback = useCallback(id => toggleCompanyShare(id), [toggleCompanyShare])

  useEffect(() => {
    setRenaming(false)
  }, [selectedTemplateId])

  return (
    <div className={classes.wrapper}>
      <Button onClick={deselectTemplates} onClickBlur transparent>
        <Cross />
      </Button>
      {selectedTemplateId ? (
        <>
          <div className={classes.preview}>{templatePreview}</div>
          <Actions id={selectedTemplateId!} renameCallback={renamingCallback} duplicateCallback={duplicateCallback} removeCallback={removeCallback} />
          <Info id={selectedTemplateId!} renaming={renaming} renameCallback={renameCallback} />
          {owned ? (
            <>
              <Sharing
                key={`MainLayout-RightPane-Template-${selectedTemplateId}-Sharing`}
                id={selectedTemplateId!}
                shareCallback={shareCallback}
                unshareCallback={unshareCallback}
                sharedWith={sharedWith || []}
              />
              <CompanySharing
                key={`MainLayout-RightPane-Template-${selectedTemplateId}-CompanySharing`}
                id={selectedTemplateId!}
                toggleCallback={toggleCompanyShareCallback}
                isShared={!!sharedCompanyTemplates?.templates?.includes(selectedTemplateId!)}
                toggling={togglingCompanyShare}
              />
            </>
          ) : null}
          {/* {owned ? <Publishing key={`MainLayout-RightPane-Template-${selectedTemplateId}-Publishing`} id={selectedTemplateId!} link="" /> : null} */}
          {owned ? <Relocation id={selectedTemplateId!} /> : null}
        </>
      ) : null}
    </div>
  )
})

Template.displayName = 'MainLayout-RightPane-Template'

export default Template
