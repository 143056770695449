import {
  DOCUMENTS_FILTERS,
  Answer,
  Answers,
  IDocument,
  PartialDocument,
  QuestionnaireOutputEntry,
  DocumentDownloadFormat,
  Signee,
  SignatureSecurityLevel,
  Segment,
  DocumentsFilter,
  BEFolder,
  PartialBEFolder,
  IListDocument,
} from '___types'

import API from './api'
import { FILE_NAME_REGEX } from '___api'

export const DOCUMENT_LIST_PER_PAGE = 10

const parseDocument = (document: any) => {
  const parsedAnswers = (document.v3Answers
    ?.map((answer: any) => {
      if (!answer.questionId) return null
      const parsed = Object.assign({}, answer, { id: answer.questionId })
      delete parsed.questionId
      return parsed
    })
    .filter((a: Answer | null) => a) || []) as Answers
  Object.assign(document, { integrations: document.externalAPIs || [] })
  delete document.externalAPIs
  Object.assign(document, { answers: parsedAnswers || [] })
  delete document.v3Answers
  Object.assign(document, { signatureSecurityLevel: document.signatureLevel })
  delete document.signatureLevel
}

class Documents extends API {
  public constructor() {
    super('documents/')
  }

  public getDocumentList = async () => {
    const res = await this.get('', undefined, undefined, 'list/documents')
    return res.data.documents.map((document: Record<string, unknown>) =>
      Object.assign(document, { category: Array.isArray(document.category) ? document.category[0] : document.category })
    ) as IListDocument[]
  }

  public getTrashedDocumentList = async () => {
    const res = await this.get('list/trashed', undefined, 'v1')
    return res.data.data.documents.map((document: Record<string, unknown>) =>
      Object.assign(document, { category: Array.isArray(document.category) ? document.category[0] : document.category })
    ) as IListDocument[]
  }

  public getDocumentFolderList = async () => {
    const res = await this.get('', undefined, undefined, 'list/documentFolders')
    return res.data.documentCategories as BEFolder[]
  }

  public getDocument = async (id?: string | null, publicFlow: boolean = false): Promise<IDocument | undefined> => {
    if (!id) return
    const res = await this.get(`${publicFlow ? 'public/' : ''}${id}`)
    // ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    parseDocument(res.data.data)
    // /////////////////////////////////////////////////////////////////////////// //
    return res.data.data as IDocument
  }

  public createDocument = async (document: PartialDocument, publicFlow: boolean = false) => {
    const { templateId, name, languages, integrationEntries, integrations, answers, answerRelevance, progress, category = '' } = document
    // ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    // remove parsing when response fixes v3Answers => answers
    const v3Answers = answers?.map(({ id, values }) => ({ questionId: id, values })) || []
    const payload = {
      new: true,
      templateId,
      name,
      languages,
      integrationEntries,
      externalAPIs: integrations,
      v3Answers,
      answerRelevance,
      progress,
      category,
    }
    const res = await this.post(publicFlow ? 'public' : '', payload)
    parseDocument(res.data.data)
    // /////////////////////////////////////////////////////////////////////////// //
    return res.data.data as IDocument
  }

  public uploadPDFDocument = async (base64data: string, filename: string) => {
    const res = await this.post(`documentsPDF`, { base64data, filename, category: [] }, undefined, 'v1')
    return res.data.data as IDocument
  }

  public updateDocument = async (document: PartialDocument, publicFlow: boolean = false) => {
    if (!document.id) return
    // ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    // remove parsing when response fixes v3Answers => answers
    const payload = Object.assign({}, document)
    if (document.answers?.length) {
      Object.assign(payload, { v3Answers: document.answers?.map(({ id, values }) => ({ questionId: id, values })) || [] })
      delete payload.answers
    }
    if (document.integrations) {
      Object.assign(payload, { externalAPIs: document.integrations })
      delete payload.integrations
    }
    const res = await this.patch(`${publicFlow ? 'public/' : ''}${document.id}`, payload)
    parseDocument(res.data.data)
    // /////////////////////////////////////////////////////////////////////////// //
    return res.data.data as IDocument
  }

  public submitDocument = async (id: string, publicFlow: boolean = false) => {
    const res = await this.post(`${publicFlow ? 'public/' : ''}${id}/next`)
    // ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    parseDocument(res.data.data)
    // /////////////////////////////////////////////////////////////////////////// //
    return res.data.data as IDocument
  }

  public publishDocument = async (id: string, payload: Record<string, unknown>) => {
    const res = await this.post(`${id}/publish`, payload)
    // ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    parseDocument(res.data.data)
    // /////////////////////////////////////////////////////////////////////////// //
    return res.data.data.document as IDocument
  }

  public approveDocument = async (id: string) => {
    if (!id) return
    const res = await this.post(`${id}/approve`)
    // ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    parseDocument(res.data.data)
    // /////////////////////////////////////////////////////////////////////////// //
    return res.data.data as IDocument
  }

  public requestRegularDocumentSignature = async (
    id: string,
    paragraphs: Segment[],
    securityLevel: SignatureSecurityLevel,
    // legislation: string,
    signees: Signee[],
    message?: string,
    splitId?: string | null,
    publicFlow: boolean = false
  ) => {
    const payload = {
      documentId: id, // documentId => id
      paragraphs,
      quality: securityLevel,
      legislation: 'ZERTES',
      signatureEntities: signees, // signatureEntities => signees
      message,
      splitId,
      requireSkribbleAccount: false,
    }
    const res = await this.post(`${publicFlow ? 'public/' : ''}${id}/signature`, payload)
    // ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    parseDocument(res.data.data)
    // /////////////////////////////////////////////////////////////////////////// //
    return res.data.data as IDocument
  }

  public requestPDFDocumentSignature = async (
    id: string,
    securityLevel: SignatureSecurityLevel,
    // legislation: string,
    signees: Signee[],
    message?: string
  ) => {
    const res = await this.post(`${id}/pdf/signature`, {
      documentId: id,
      quality: securityLevel,
      legislation: 'ZERTES',
      signatureEntities: signees,
      message,
      requireSkribbleAccount: false,
    })
    return res.data.data as IDocument
  }

  public downloadRegularDocument = async (
    id: string,
    payload: { storageId: string; paragraphs: unknown[] },
    format: DocumentDownloadFormat = 'docx',
    publicFlow: boolean = false
  ) => {
    if (!id) return
    const res = await this.post(`${publicFlow ? 'public/' : ''}${id}/export/${format}`, payload, { responseType: 'blob' })
    const { headers, data } = res
    const filename = headers['content-disposition']?.match(FILE_NAME_REGEX)?.groups?.filename || ''
    const link = window.document.createElement('a')
    link.href = window.URL.createObjectURL(new Blob([data]))
    link.setAttribute('download', filename)
    window.document.body.appendChild(link)
    link.click()
    window.document.body.removeChild(link)
  }

  public downloadPDFDocument = async (id: string) => {
    if (!id) return
    const res = await this.get(`documentsPDF/${id}`, { responseType: 'arraybuffer' }, 'v1')
    const { headers, data } = res
    const filename = headers['content-disposition']?.match(FILE_NAME_REGEX)?.groups?.filename || ''
    const link = window.document.createElement('a')
    link.href = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
    link.setAttribute('download', filename)
    window.document.body.appendChild(link)
    link.click()
    window.document.body.removeChild(link)
  }

  public downloadSignedDocument = async (id: string, splitId?: string | null, publicFlow: boolean = false) => {
    if (!id) return
    const res = await this.get(`${publicFlow ? 'public/' : ''}${id}/${splitId || 'general'}/signature/pdf/export`, {
      responseType: 'arraybuffer',
    })
    const { headers, data } = res
    const filename = headers['content-disposition']?.match(FILE_NAME_REGEX)?.groups?.filename || ''
    const link = window.document.createElement('a')
    link.href = window.URL.createObjectURL(new Blob([data]))
    link.setAttribute('download', filename)
    window.document.body.appendChild(link)
    link.click()
    window.document.body.removeChild(link)
  }

  public sendDocumentSignatureReminder = async (id: string, requestId: string, email: string) => {
    await this.post(`${id}/signature/${requestId}/reminder`, { email }, { responseType: 'arraybuffer' })
  }

  public duplicateDocument = async (id: string) => {
    if (!id) return
    const res = await this.post(`${id}/duplicate`)
    return res.data.data as IDocument
  }

  public removeDocument = async (id: string) => {
    await this.patch(id, { status: 'trashed' })
    return
  }

  public generateDocumentTitle = async (payload: { templateName: string; questionnaireOutput: QuestionnaireOutputEntry[] }) => {
    const res = await this.post('ai/name', { templateName: payload.templateName, answers: payload.questionnaireOutput })
    return res.data.data.documentName
  }

  public createDocumentFolder = async (name: string, parentFolder?: string | null) => {
    const payload = { name, parentCategoryId: '' }
    if (parentFolder && !Object.values(DOCUMENTS_FILTERS).includes(parentFolder as DocumentsFilter))
      Object.assign(payload, { parentCategoryId: parentFolder })
    const res = await this.post('', payload, undefined, 'v1', 'documentCategories')
    return res.data.data as BEFolder
  }

  public updateDocumentFolder = async (folder: PartialBEFolder) => {
    if (!folder.id) return
    const res = await this.post(`${folder.id}`, folder, undefined, 'v1', 'documentCategories')
    return res.data.data as BEFolder
  }

  public removeDocumentFolder = async (id: string) => {
    await this.post(id, { status: 'trashed' }, undefined, 'v1', 'documentCategories')
    return
  }
}

export const documentsAPI = new Documents()

export default documentsAPI
