import React, { FunctionComponent, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import { SECURITY_LEVELS } from '___types'
import { useDocument } from '___hooks'
import { Copy } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import useModal from 'Modal'
import {
  MainLayoutRightPaneDocumentPublishingModalContentContext,
  MainLayoutRightPaneDocumentPublishingProps,
  mainLayoutRightPaneDocumentPublishingClasses as classes,
} from 'Layouts/___MainLayout'
import ModalContent from './Publishing.ModalContent'

const defaultSettings = {
  notifyEmail: '',
  includeEmail: false,
  previewAvailable: false,
  signatureAvailable: false,
  signatureConfig: { concludeText: 'Configure', concludeDisabled: false, security: SECURITY_LEVELS.BASE, signees: [], message: '' },
  expires: false,
  expirationTime: DateTime.now().toISO(),
  singleUse: false,
  split: null,
}

export const Publishing: FunctionComponent<MainLayoutRightPaneDocumentPublishingProps> = React.memo(({ id }) => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'layout.main.rightPane.document' })
  const { data: { signatureSecurityLevel, publicSettings, isPublic, templateId } = {}, template, publish, publishing } = useDocument(id)
  const { data: { splits } = {} } = template
  const defaultContext = useMemo(
    () =>
      Object.assign({}, defaultSettings, publicSettings, {
        signatureConfig: Object.assign({}, defaultSettings.signatureConfig, publicSettings?.signatureConfig, { security: signatureSecurityLevel }),
      }),
    [signatureSecurityLevel, publicSettings]
  )
  const context = useRef(JSON.parse(JSON.stringify(defaultContext)) as MainLayoutRightPaneDocumentPublishingModalContentContext)
  const open = useModal({
    header: 'Publish document',
    close: false,
    content: (...params) => (
      <ModalContent conclude={params[2]} context={params[3] as MainLayoutRightPaneDocumentPublishingModalContentContext} splits={splits} />
    ),
    context: context.current,
    onClose: context => Object.assign(context, JSON.parse(JSON.stringify(defaultContext))),
    onConclude: (context, settings) => {
      const { notifyEmail, includeEmail, previewAvailable, signatureAvailable, signatureConfig, expires, expirationTime, singleUse, split } =
        Object.assign(context, settings) as MainLayoutRightPaneDocumentPublishingModalContentContext
      publish(notifyEmail, includeEmail, previewAvailable, signatureAvailable, signatureConfig, expires, expirationTime, singleUse, split)
    },
    footer: false,
  })

  useEffect(() => {
    Object.assign(context.current, publicSettings, {
      signatureConfig: Object.assign({}, context.current.signatureConfig, publicSettings?.signatureConfig),
    })
  }, [publicSettings])

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <span>{translate(isPublic ? 'published' : 'publishing')}</span>
        {isPublic ? (
          <Button
            onClick={() => navigator.clipboard.writeText(`${document.location.origin}/public/${templateId}/${id}`)}
            onClickBlur
            disabled={publishing}
          >
            {translate('copy-link')}
            <Copy type="link" />
          </Button>
        ) : null}
        <Button onClick={() => open()} onClickBlur disabled={publishing} loading={publishing}>
          {translate(isPublic ? 'republish' : 'publish')}
        </Button>
      </div>
    </div>
  )
})

Publishing.displayName = 'MainLayout-RightPane-Document-Publishing'

export default Publishing
