import { DateTime } from 'luxon'

import { ValuesOf, TupleToUnion, ReadonlyRecord, RecursiveRecord, ExternalServiceField } from '.'
import { PaperName, PageLayout, Time, Author, CASUS_KEYSTRINGS, CASUS_IDS, SignatureSecurityLevel, Signee } from './types.general'
import { WizardEditorStylesType } from './types.editor'

import de from 'assets/flags/de.svg'
import fr from 'assets/flags/fr.svg'
import it from 'assets/flags/it.svg'
import gb from 'assets/flags/gb.svg'

// =========================================================================================== //
// ======================================== CONSTANTS ======================================== //
// =========================================================================================== //
export const TEMPLATES_FILTERS = {
  MINE: 'mine',
  COMPANY: 'company',
  SHARED: 'shared',
  PUBLIC: 'public',
  TRASHED: 'trashed',
} as const
type TemplatesFilters = typeof TEMPLATES_FILTERS
type TemplatesFiltersKeys = keyof TemplatesFilters
export type TemplatesFilter = TemplatesFilters[TemplatesFiltersKeys]

export const TEMPLATE_FILTER_MAP = {
  [TEMPLATES_FILTERS.MINE]: 'my',
  [TEMPLATES_FILTERS.TRASHED]: 'bin',
} as const
type TemplatesFilterMap = typeof TEMPLATE_FILTER_MAP
export type TemplatesFilterMapKeys = keyof TemplatesFilterMap
type TemplatesFilterMapValues = TemplatesFilterMap[TemplatesFilterMapKeys]
export type MappedTemplatesFilter = TemplatesFilterMapValues | Exclude<TemplatesFilter, TemplatesFilterMapKeys>

export const mapTemplatesFilter = (templatesFilter: string): MappedTemplatesFilter | string =>
  TEMPLATE_FILTER_MAP[templatesFilter as TemplatesFilterMapKeys] || templatesFilter
export const MAPPED_TEMPLATES_FILTERS = (Object.entries(TEMPLATES_FILTERS) as [TemplatesFiltersKeys, TemplatesFilter][]).reduce(
  (acc, [key, value]) => Object.assign(acc, { [key]: mapTemplatesFilter(value) }),
  {}
) as ReadonlyRecord<TemplatesFiltersKeys, MappedTemplatesFilter>

export const COMPUTED_VALUES = {
  TDD: {
    value: () => `TDD:{{${CASUS_KEYSTRINGS.COMPUTED} type="date" value="${DateTime.now().startOf('day').toISO()}"}}`,
    label: "Today's date",
  },
  FOTM: {
    value: () => `FOTM:{{${CASUS_KEYSTRINGS.COMPUTED} type="date" value="${DateTime.now().startOf('month').toISO()}"}}`,
    label: 'First of This Month',
  },
  NFOM: {
    value: () => `NFOM:{{${CASUS_KEYSTRINGS.COMPUTED} type="date" value="${DateTime.now().startOf('month').plus({ month: 1 }).toISO()}"}}`,
    label: 'Next First of Month',
  },
} as const
// =========================================================================================== //

// =========================================================================================== //
// =================================== REGULAR EXPRESSIONS =================================== //
// =========================================================================================== //
export const VALUE_SOURCE_TYPE_MATCH = new RegExp('^(?<type>computed|question|external):(?<id>.*)$')
// =========================================================================================== //

export type Integration = { id: string; cue: string; fields: ExternalServiceField[] }
export type Integrations = Record<string, Integration>

export const LANGUAGES = {
  GERMAN_SWISS: 'de-CH',
  FRENCH_SWISS: 'fr-CH',
  ITALIAN_SWISS: 'it-CH',
  ENGLISH_BRITISH: 'en-GB',
} as const
export type LanguageValue = ValuesOf<typeof LANGUAGES>
export type Languages = {
  available: LanguageValue[] | undefined
  selected: LanguageValue[] | undefined
  select: 'single' | 'multi'
}

export const LANGUAGE_INFO = {
  [LANGUAGES.GERMAN_SWISS]: { label: 'German', icon: de },
  [LANGUAGES.FRENCH_SWISS]: { label: 'French', icon: fr },
  [LANGUAGES.ITALIAN_SWISS]: { label: 'Italian', icon: it },
  [LANGUAGES.ENGLISH_BRITISH]: { label: 'English', icon: gb },
} as const

// =========================================================================================== //
// ======================================== QUESTIONS ======================================== //
// =========================================================================================== //
type QuestionAdvancedRule = {} // DEFINE WHEN IMPLEMENTING RULES
type QuestionAdvancedRules = QuestionAdvancedRule[]
type QuestionAdvancedConfiguration = { visibility: 'show' | 'hide'; logic: 'and' | 'or'; subQuestionTo: string; rules: QuestionAdvancedRules }
type QuestionDetails = { description: string; example: string; hint: string }
export type OptionLimits = { min: number | string; max: number | string; step: number | string }
export const OPTION_VALUE_TYPES = { STRING: 'string', NUMBER: 'number', DATE: 'date', DATE_TIME: 'date-time', CURRENCY: 'currency' } as const
export type OptionValueTypeUnionType = ValuesOf<typeof OPTION_VALUE_TYPES>
interface IOptionBase {
  id: string
  value: string
  label: string
  valueType: OptionValueTypeUnionType
  markers: string[]
  text?: string
  placeholder?: string
  limits?: OptionLimits
}
export const OPTION_TYPES = { STATIC: 'static', DYNAMIC: 'dynamic' } as const
type OptionTypesType = typeof OPTION_TYPES
export type OptionTypesUnionType = OptionTypesType[keyof OptionTypesType]
export type StaticOption = IOptionBase & { type: OptionTypesType['STATIC'] }
export type DynamicOption = IOptionBase & { type: OptionTypesType['DYNAMIC'] }
export type Option = StaticOption | DynamicOption
export type Options = Option[]
export const OPTION_GROUP_SELECT = { SINGLE: 'single', MULTI: 'multi' } as const
type OptionGroupSelectType = typeof OPTION_GROUP_SELECT
export type OptionGroupSelectUnionType = OptionGroupSelectType[keyof OptionGroupSelectType]
type OptionGroupSelect = { select: OptionGroupSelectUnionType; minimum: number; maximum: number; enforceLimit: boolean }
export type OptionGroup = { id: string; label: string; options: Options; select: OptionGroupSelect }
export type OptionGroups = OptionGroup[]
export type Question = {
  id: string
  text: string
  markers: string[]
  optionGroups: OptionGroups
  questionDetails: QuestionDetails
  advanced: QuestionAdvancedConfiguration
  isPrivate: boolean
}
export type Questions = Question[]
// =========================================================================================== //

// =========================================================================================== //
// ===================================== QUESTION LAYOUT ===================================== //
// =========================================================================================== //
interface IQuestionLayoutGroupBase {
  id: string
  label: string
  questions: string[] // Change to questionIds when appropriate --- will break backwards compatibility and previously created templates will not work!
}
type QuestionLayoutSubQuestionGroup = IQuestionLayoutGroupBase & { type: 'sub-questions' }
type QuestionLayoutRegularGroup = IQuestionLayoutGroupBase & { type: 'group' }
type QuestionLayoutLooseGroup = IQuestionLayoutGroupBase & { type: 'loose' }
export type QuestionLayoutGroup = QuestionLayoutLooseGroup | QuestionLayoutRegularGroup | QuestionLayoutSubQuestionGroup
export type QuestionLayout = QuestionLayoutGroup[]
// =========================================================================================== //

// =========================================================================================== //
// ======================================== LOCATIONS ======================================== //
// =========================================================================================== //
export const STATIC_CALCULATION_FUNCTIONS = {
  addition: (sourceValue: string, modifierValue: string) => String(Number(sourceValue) + Number(modifierValue)),
  subtraction: (sourceValue: string, modifierValue: string) => String(Number(sourceValue) - Number(modifierValue)),
  multiplication: (sourceValue: string, modifierValue: string) => String(Number(sourceValue) * Number(modifierValue)),
  division: (sourceValue: string, modifierValue: string) => String(Number(sourceValue) / Number(modifierValue)),
} as const
export type StaticCalculationType = keyof typeof STATIC_CALCULATION_FUNCTIONS
export const MODIFIER_TYPES = { STRING: 'string', NUMBER: 'number', DATE: 'date' } as const
export type ModifierType = ValuesOf<typeof MODIFIER_TYPES>
export type LocationModifier = { id: string; type: ModifierType; label: string; fn: StaticCalculationType; value: string }
export type ValueSources = string[]
export type OptionConditional = string
export type LanguageConditional = LanguageValue
export type SplitConditional = string
export type RuleConditional = Record<string, unknown> & { id: string }
export type Conditionals = { options: OptionConditional[]; languages: LanguageConditional[]; splits: SplitConditional[]; rules: RuleConditional[] }
export const LOCATION_TYPES = { SEGMENTS: 'segments', TEXT: 'text' } as const
export type LocationType = ValuesOf<typeof LOCATION_TYPES>
interface ILocationBase {
  id: string
  type: LocationType
  range: [number, number]
  label: string
  color: 'variant-1' | 'variant-2' | 'variant-3' | 'variant-4'
  valueSources: ValueSources
  conditionals: Conditionals
  defaultKeep: boolean
  keep: boolean
  replace?: string
  combine?: boolean
  calculation?: string
  valueMap?: Record<string, string>
  questionId?: string // ------------ DEPRICATE WHEN POSSIBLE
  optionIds?: string[] // ----------- DEPRICATE WHEN POSSIBLE
  modifiers?: LocationModifier[] // - DEPRICATE WHEN POSSIBLE
}
interface ISegmentsLocationSpecificProperties {
  type: typeof LOCATION_TYPES.SEGMENTS
  contentCustomStyle: string
  contentStyles: string[]
}
interface ITextLocationSpecificProperties {
  type: typeof LOCATION_TYPES.TEXT
  contentText: string
  concatString: string
}
type LocationTypeSpecificProperties = ISegmentsLocationSpecificProperties | ITextLocationSpecificProperties
type Location<T extends LocationTypeSpecificProperties> = ILocationBase & { [P in keyof T]: T[P] }
export type SegmentsLocation = Location<ISegmentsLocationSpecificProperties>
export type SegmentsLocations = { [key: string]: SegmentsLocation[] }
export type TextLocation = Location<ITextLocationSpecificProperties>
export type TextLocations = { [key: string]: TextLocation[] }
export type Locations = { segments: SegmentsLocations; text: TextLocations }
// =========================================================================================== //

// =========================================================================================== //
// =========================================== CSS =========================================== //
// =========================================================================================== //
export type CSSData = RecursiveRecord<string, string>
// =========================================================================================== //

// =========================================================================================== //
// ======================================== NUMBERING ======================================== //
// =========================================================================================== //
type NumberingSystemLevel = { styleName: string; prefix: string; suffix: string; combined: boolean; combineString: string; type: string }
export type Numbering = NumberingSystemLevel[]
type Numberings = { [key: string]: Numbering }
export type NumberingSystem = { [key: string]: Numbering }
// =========================================================================================== //

// ========================================================================================== //
// ===================================== DATA STRUCTURE ===================================== //
// ========================================================================================== //
export const NESTED_STRUCTURE_KEYS = ['segments', 'textChunks', 'header', 'body', 'footer', 'cells', 'content'] as const
type NestedStructureKeyArray = typeof NESTED_STRUCTURE_KEYS
export type NestedStructureKeys = TupleToUnion<NestedStructureKeyArray>
export const SEGMENT_TYPES = {
  CONTAINER: 'container',
  MARKER: 'mark',
  PARAGRAPH: 'paragraph',
  TEXT_CHUNK: 'chunk',
  TABLE: 'table',
  TABLE_HEADER: 'header',
  TABLE_BODY: 'body',
  TABLE_FOOTER: 'footer',
  TABLE_ROW: 'row',
  TABLE_CELL: 'cell',
  IMAGE: 'image',
} as const
export type SegmentType = ValuesOf<typeof SEGMENT_TYPES>
const GENERAL_KEYS = { TYPE: 'type', TAG: 'tag', CUSTOM_STYLE: 'customStyle', STYLES: 'styles' } as const
export const SEGMENT_KEYS = {
  [SEGMENT_TYPES.PARAGRAPH]: ['id', 'textChunks', 'break'],
  [SEGMENT_TYPES.TEXT_CHUNK]: ['text'],
  [SEGMENT_TYPES.TABLE]: ['id', 'header', 'body', 'footer', 'break'],
  [SEGMENT_TYPES.TABLE_ROW]: ['id', 'cells'],
  [SEGMENT_TYPES.TABLE_CELL]: ['id', 'content'],
  ALL: [GENERAL_KEYS.TYPE, GENERAL_KEYS.TAG, GENERAL_KEYS.CUSTOM_STYLE, GENERAL_KEYS.STYLES],
} as const
export type SegmentKeys = ValuesOf<typeof SEGMENT_KEYS>[number]
export const RETAIN_KEYS = ['v2Styles', 'fldChar', 'instrText', 'drawing'] as const
export const KEY_MAP = { [GENERAL_KEYS.CUSTOM_STYLE]: 'styleName' }
export const TABLE_SECTION_TYPES = [SEGMENT_TYPES.TABLE_HEADER, SEGMENT_TYPES.TABLE_BODY, SEGMENT_TYPES.TABLE_FOOTER] as const
export type TableSectionType = TupleToUnion<typeof TABLE_SECTION_TYPES>
export type TableSectionsType = { [K in TableSectionType]?: TableRows }
interface IDataStructureObjectBase {
  customStyle: string
  styles: string[]
  type: SegmentType
}
type SectionLayout = { paperName: PaperName; margins: { top: number; left: number; bottom: number; right: number } }
export type PageBreak = { type: 'page' }
export type SectionBreak = { type: 'section'; id: string; layout: SectionLayout }
type Break = PageBreak | SectionBreak
interface ITextChunkProperties {
  text: string
  type: typeof SEGMENT_TYPES.TEXT_CHUNK
}
interface IParagraphProperties {
  id: string
  textChunks: TextChunks
  type: typeof SEGMENT_TYPES.PARAGRAPH
  break: Break
}
interface ITableCellProperties {
  id: string
  content: Segments
  type: typeof SEGMENT_TYPES.TABLE_CELL
}
interface ITableRowProperties {
  id: string
  cells: TableCells
  type: typeof SEGMENT_TYPES.TABLE_ROW
}
interface ITableProperties {
  id: string
  header: TableRows
  body: TableRows
  footer: TableRows
  type: typeof SEGMENT_TYPES.TABLE
  break: Break
}

type DataStructureObjectProperties = IParagraphProperties | ITextChunkProperties | ITableProperties | ITableRowProperties | ITableCellProperties
type DataStructureObject<T extends DataStructureObjectProperties> = IDataStructureObjectBase & { [P in keyof T]: T[P] }

export type TextChunkObject = DataStructureObject<ITextChunkProperties>
export type TextChunks = TextChunkObject[]
export type ParagraphObject = DataStructureObject<IParagraphProperties>
export type TableCellObject = DataStructureObject<ITableCellProperties>
export type TableCells = TableCellObject[]
type TableRowObject = DataStructureObject<ITableRowProperties>
export type TableRows = TableRowObject[]
export type TableObject = DataStructureObject<ITableProperties>
export type Segment = ParagraphObject | TableObject
export type Segments = Segment[]
export type Header = { id: string; segments: Segments }
export type Footer = { id: string; segments: Segments }

export type WizardStateDataStructureNumbering = { [key: string]: { system: string; value: string } }
export type WizardStateDataStructureSection = { id: string; title: string; layout: PageLayout; pages: [number, number][] }
export type WizardStateDataStructureSections = WizardStateDataStructureSection[]
export type DataStructure = {
  id: typeof CASUS_IDS.DATASTRUCTURE_ID
  storageId: string
  segments: Segments
  headers: Header[]
  footers: Footer[]
  numberingSystem: NumberingSystem
  numberings: Numberings

  // ============== GENERATED ============== //
  sections: WizardStateDataStructureSections
  numbering: WizardStateDataStructureNumbering
}
// ========================================================================================== //

export interface IListTemplate {
  id: string
  name: string
  isPremium: Boolean
  category: string

  authorId: string
  created: Time
  edited: Time
  sharingEnabled: boolean
  sharedWith: string[]
  companyShared: boolean
  isPublic: Boolean
  version: 'v2' | 'v3'
}
export type TemplatePublishing = {
  previewAvailable?: boolean
  signatureAvailable?: boolean
  signatureConfig?: { security?: SignatureSecurityLevel; signees?: Signee[]; message?: string }
  exipres?: boolean
  expirationTime?: string | null
}
export interface ITemplate {
  id: string
  status: string
  name: string
  description: string
  contentVersionId: string
  isPremium: string
  category: string

  dataStructure: DataStructure
  cssData: CSSData
  styles: WizardEditorStylesType
  locations: Locations
  questionLayout: QuestionLayout
  questions: Questions
  languages: Languages
  integrations: Integrations
  splits: Record<string, string>
  author: Author
  displayAuthor: Author
  created: Time
  edited: Time
  sharedWith: Author[]
  isPublic: boolean
  publicSettings?: TemplatePublishing
  approvals: string[] // update after implementing complex approvals
  version: 'v1' | 'v2' | 'v3'

  beingDuplicated?: boolean
  beingDeleted?: boolean
  paywallBlocked?: boolean
}
export type PartialTemplate = { [K in keyof ITemplate]?: ITemplate[K] }

export interface ITemplateFolder {
  id: string
  authorId: string
  parentFolderId: string
  name: string
  sharingEnabled: boolean
  sharedWith: Author[]
  author: Author
  created: Time
  edited: Time
}
