import React, { forwardRef, Dispatch, SetStateAction, RefCallback, useState, useMemo, useCallback, useEffect, useImperativeHandle } from 'react'

import { CASUS_INPUT_TYPES } from '___types'
import { debounceFunction } from 'utilities/helpers'
import { Check, Cross } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import TextInput from './Input.Text'
import MultilineInput from './Input.Multiline'
import NumberInput from './Input.Number'
import DateTimeInput from './Input.DateTime'
// import CurrencyInput from './Input.Currency'
import { InputProps, classes } from '.'

export const Input = React.memo(
  forwardRef<HTMLPreElement | HTMLInputElement | undefined, InputProps>((props, ref) => {
    const {
      id,
      className: cn,
      disabled,
      dataSet,
      type,
      defaultValue,
      value,
      placeholder,
      options,
      debouncedInput,
      autoselect,
      showActionButtons = true,
      onInput,
      onConfirm,
      onDiscard,
    } = props

    // ================================================================================================== //
    // ============================================= STATES ============================================= //
    // ================================================================================================== //
    const [debounceTimeout, setDebounceTimeout] = useState(undefined as void | NodeJS.Timeout)
    const [wrapper, setWrapper]: [HTMLDivElement | undefined, Dispatch<SetStateAction<HTMLDivElement | undefined>>] = useState()
    const [input, setInput]: [
      HTMLPreElement | HTMLInputElement | undefined,
      Dispatch<SetStateAction<HTMLPreElement | HTMLInputElement | undefined>>
    ] = useState()
    // ================================================================================================== //
    //
    //
    //
    // =================================================================================================== //
    // ============================================== MEMOS ============================================== //
    // =================================================================================================== //
    const emphasis = ['emphasized', 'primary', 'secondary', 'tertiary', 'transparent'].find(prop => props[prop as keyof InputProps])
    const baselineDataSet = useMemo(() => ({ emphasis, disabled }), [emphasis, disabled])
    const className = useMemo(() => [classes.wrapper, cn].filter(c => c).join(' '), [cn])
    const onInputCallback = useMemo(
      () => (debouncedInput && typeof onInput === 'function' ? debounceFunction(onInput, debouncedInput) : onInput),
      [debouncedInput, onInput]
    )
    // =================================================================================================== //
    //
    //
    //
    // =================================================================================================== //
    // ============================================ CALLBACKS ============================================ //
    // =================================================================================================== //
    const wrapperRef: RefCallback<HTMLDivElement> = useCallback(node => node && setWrapper(node), [])
    const inputRef: RefCallback<HTMLPreElement | HTMLInputElement> = useCallback(node => node && setInput(node), [])
    const onInputHandler = useCallback(
      event => {
        event.persist()
        onInputCallback && setDebounceTimeout(onInputCallback(event))
      },
      [onInputCallback]
    )
    const onConfirmHandler = useCallback(
      event => {
        debounceTimeout && clearTimeout(debounceTimeout)
        typeof onConfirm === 'function' && onConfirm(event)
        input?.blur()
      },
      [debounceTimeout, onConfirm, input]
    )
    const onDiscardHandler = useCallback(
      event => {
        debounceTimeout && clearTimeout(debounceTimeout)
        typeof onDiscard === 'function' && onDiscard(event)
        input?.focus()
      },
      [debounceTimeout, onDiscard, input]
    )
    // =================================================================================================== //
    //
    //
    //
    // =================================================================================================== //
    // ============================================= EFFECTS ============================================= //
    // =================================================================================================== //
    useEffect(() => {
      if (wrapper) {
        Object.keys(wrapper.dataset).forEach(key => delete wrapper.dataset[key])
        Object.entries(Object.assign({}, baselineDataSet, dataSet)).forEach(
          ([key, value]) => value !== undefined && (wrapper.dataset[key] = String(value))
        )
      }
    }, [wrapper, dataSet, baselineDataSet])
    // =================================================================================================== //

    const render = useMemo(() => {
      if (type === CASUS_INPUT_TYPES.TEXT)
        return (
          <TextInput
            ref={inputRef}
            disabled={disabled}
            defaultValue={defaultValue as string}
            value={value as string}
            placeholder={placeholder}
            format={options?.format}
            autoselect={autoselect}
            onInput={onInputHandler}
            onEnter={onConfirmHandler}
            onEscape={onDiscardHandler}
          />
        )
      if (type === CASUS_INPUT_TYPES.MULTILINE)
        return (
          <MultilineInput
            ref={inputRef}
            disabled={disabled}
            defaultValue={defaultValue as string}
            value={value as string}
            placeholder={placeholder}
            autoselect={autoselect}
            onInput={onInputHandler}
            onEnter={onConfirmHandler}
            onEscape={onDiscardHandler}
          />
        )
      if (type === CASUS_INPUT_TYPES.NUMBER)
        return (
          <NumberInput
            ref={inputRef}
            disabled={disabled}
            defaultValue={defaultValue as number}
            value={value as number}
            min={options?.min}
            max={options?.max}
            step={options?.step}
            autoselect={autoselect}
            onInput={onInputHandler}
            onEnter={onConfirmHandler}
            onEscape={onDiscardHandler}
          />
        )
      if (type === CASUS_INPUT_TYPES.DATE_TIME)
        return (
          <DateTimeInput
            ref={inputRef}
            disabled={disabled}
            defaultValue={defaultValue as string}
            value={value as string}
            inputTime={options?.inputTime}
            autoselect={autoselect}
            onInput={onInputHandler}
            onEnter={onConfirmHandler}
            onEscape={onDiscardHandler}
          />
        )
      // if (type === CASUS_INPUT_TYPES.CURRENCY) return <CurrencyInput onInput={onInputHandler} />
      if (type === CASUS_INPUT_TYPES.CURRENCY)
        return (
          <TextInput
            ref={inputRef}
            disabled={disabled}
            defaultValue={defaultValue as string}
            value={value as string}
            placeholder={placeholder}
            autoselect={autoselect}
            onInput={onInputHandler}
            onEnter={onConfirmHandler}
            onEscape={onDiscardHandler}
          />
        )
      return null
    }, [type, disabled, inputRef, defaultValue, value, placeholder, autoselect, onInputHandler, onConfirmHandler, onDiscardHandler, options])

    useImperativeHandle(ref, () => input)

    return (
      <div ref={wrapperRef} id={id} className={className} onFocus={() => input?.focus()}>
        {render}
        <div className={classes.actionButtons.wrapper}>
          {typeof onConfirm === 'function' && showActionButtons ? (
            <Button
              className={classes.actionButtons.confirmButton}
              dataSet={baselineDataSet}
              tabbable={false}
              onClick={onConfirmHandler}
              onFocus={event => event.stopPropagation()}
            >
              <Check />
            </Button>
          ) : null}
          {(input?.innerText || (input as HTMLInputElement)?.value) && typeof onDiscard === 'function' && showActionButtons ? (
            <Button
              className={classes.actionButtons.discardButton}
              tabbable={false}
              onClick={onDiscardHandler}
              onFocus={event => event.stopPropagation()}
            >
              <Cross />
            </Button>
          ) : null}
        </div>
      </div>
    )
  })
)

Input.displayName = 'Casus-Components-Input'

export default Input
